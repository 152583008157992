@import './colors.css';

.slotManager_row {
  padding-top: 1rem;
}

.addeditslot_row {
  margin-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.isRotated svg {
  transform: rotate(-90deg);
}

.checkbox_row {
  padding-bottom: 0.5rem;
}

.danger {
  color: rgb(255, 77, 79);
}

.action_button {
  font-size: 0.7rem;
}

.info_button {
  font-size: 0.7rem;
  width: 2rem;
}

.slot_tableHeader {
  font-size: 0.9rem;
}

.slot_tablebody {
  font-size: 0.8rem;
}

.select_message {
  margin-top: 1rem;
  margin-left: 1rem;
}

.slot_manager_header.modal-header {
  border-bottom: 0 !important;
  padding-left: 2rem;
  padding-top: 1rem;
}

.slot_manager_footer.modal-footer {
  border-top: 0;
}

.slot_manager_body {
  padding-top: 1rem;
  padding-left: 2rem;
}

.slot_manager_radio {
  padding-bottom: 1rem;
}

.slot_manager_modal {
  width: 400px;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
}

.form-check-label {
  padding-left: 0.5rem;
}

.edit_header_icon_save {
  cursor: pointer;
  margin-right: 15px;
  font-size: 1.5rem;
  color: var(--primary--color)
}

.edit_header_icon_close {
  cursor: pointer;
  font-size: 1.5rem;
  color: grey
}

.accordion_card {
  margin-bottom: 0px;
}

.slot_table_date {
  margin-bottom: 1rem;
}

.selected_slot_info {
  margin-bottom: 1rem;
}

.manual_icon_slot_manager {
  float: right;
  font-size: 1rem;
  color: var(--primary--color);
  cursor: pointer;
  margin-top: 6px;
}

.test_switch {
  max-height: 10px;
}